import React, { useState } from 'react';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
import { useHistory } from "react-router-dom";


// Require the Node Slack SDK package (github.com/slackapi/node-slack-sdk)
const { WebClient, LogLevel } = require("@slack/web-api");

// WebClient insantiates a client that can call API methods
// When using Bolt, you can use either `app.client` or the `client` passed to listeners.
const client = new WebClient("xoxb-1388128650545-2562174752976-inC9n5soNxVtEIIB2s8M0aKe", {
  // LogLevel can be imported and used to make debugging simpler
  logLevel: LogLevel.DEBUG
});
delete client["axios"].defaults.headers["User-Agent"];




function Contact() {


const history = useHistory();

const routeChange = () =>{
  let path = `/about`;
  history.push(path);
}

  const taskEngineHost = 'localhost:5000/tasks'
  const slackChannelId = 'C02FRGLK77F'

  const [values, setValues] = useState({
    first_name: '', last_name: '', business_name: '', phone: '', business_type: '', license: ''
  });
  const set = name => {
    return ({ target: { value } }) => {
      setValues(oldValues => ({...oldValues, [name]: value }));
    }
  };


  const slackMessage = "Submission from Contact Form on Siphon Marketing \n" +
      `First Name: ${values.first_name} \n ` +
      `Last Name: ${values.last_name} \n` +
      `Business Name: ${values.business_name} \n ` +
      `Phone: ${values.phone} \n ` +
      `Business Type: ${values.business_type} \n ` +
      `License: ${values.license} \n ` +
      "CC: @Jacob Hamilton "



  const onSubmit = async (event) => {

    event.preventDefault(); // Prevent default submission
    console.log("here are values", values)

    // try {
    //
    //
    //   // await saveFormData();
    //   // alert('Your registration was successfully submitted!');
    //   // setValues({
    //   //   first_name: '', last_name: '', business_name: '', phone: '', business_type: '', license: ''
    //   // });
    // } catch (e) {
    //   alert(`Registration failed! ${e.message}`);
    // }
    // ID of the channel you want to send the message to

try {
  // Call the chat.postMessage method using the WebClient
  const result = await client.chat.postMessage({
    channel: slackChannelId,
    text: slackMessage
  });
  setValues({
        email: '', last_name: '', business_name: '', phone: '', business_type: '', license: ''
      });

  routeChange()


  // console.log("here is result", result);
}
catch (error) {
  console.error(error);
}
  };
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">

          {/*  Site header */}
          <Header/>

          {/*  Page content */}
          <main className="flex-grow">

            {/*  Page illustration */}
            <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
              <PageIllustration/>
            </div>

            <section className="relative">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                  {/* Page header */}
                  <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                    <h1 className="h1 font-red-hat-display mb-4">Get started with Siphon today</h1>
                    <p className="text-xl text-gray-600 dark:text-gray-400">We'll contact you within 1 hour</p>
                  </div>

                  {/* Contact form */}
                  <form className="max-w-xl mx-auto" onSubmit={onSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="first-name">First Name <span className="text-red-600">*</span></label>
                        <input id="first_name" value={values.first_name}  onChange={set('first_name')} type="text" className="form-input w-full"
                               placeholder="Enter your first name" required/>
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="last-name">Last Name <span className="text-red-600">*</span></label>
                        <input id="last_name" value={values.last_name} onChange={set('last_name')} type="text" className="form-input w-full"
                               placeholder="Enter your last name" required/>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="company">Business name <span className="text-red-600">*</span></label>
                        <input id="business_name" value={values.business_name} onChange={set('business_name')} type="text" className="form-input w-full"
                               placeholder="Enter your company name" required/>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="phone">Phone Number <span className="text-red-600">*</span></label>
                        <input id="phone" value={values.phone} onChange={set('phone')} type="tel" className="form-input w-full" placeholder="Enter your phone number"
                               required/>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="business_type">Business Type <span className="text-red-600">*</span></label>
                        <select id="business_type" value={values.business_type} onChange={set('business_type')} className="form-select w-full" required>
                          <option>Storefront</option>
                          <option>Delivery Service</option>
                          <option>Storefront + Delivery</option>
                        </select>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-1"
                               htmlFor="company">License number <span className="text-red-600">*</span></label>
                        <input id="license" value={values.license} onChange={set('license')} type="text" className="form-input w-full"
                               placeholder="Enter your license number" required/>
                      </div>
                    </div>
                    {/*<div className="flex flex-wrap -mx-3 mb-5">*/}
                    {/*  <div className="w-full px-3">*/}
                    {/*    <div className="flex justify-between items-center mb-1">*/}
                    {/*      <label className="block text-gray-800 dark:text-gray-300 text-sm font-medium" htmlFor="message">Message</label>*/}
                    {/*      <span className="text-sm text-gray-500">Optional</span>*/}
                    {/*    </div>*/}
                    {/*    <textarea id="message" rows="4" className="form-textarea w-full" placeholder="What would you like to discuss today? "></textarea>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="flex flex-wrap -mx-3 mb-5">
                      <div className="w-full px-3">
                        <div className="block text-gray-800 dark:text-gray-300 text-sm font-medium mb-3">Tell us about
                          your role
                        </div>
                        <label className="flex items-center mb-2">
                          <input type="radio" className="form-radio" name="role"/>
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">Owner</span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input type="radio" className="form-radio" name="role"/>
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">Manager</span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input type="radio" className="form-radio" name="role"/>
                          <span
                              className="text-sm text-gray-600 dark:text-gray-400 ml-3">Design / Marketing / Sales</span>
                        </label>
                        <label className="flex items-center mb-2">
                          <input type="radio" className="form-radio" name="role"/>
                          <span className="text-sm text-gray-600 dark:text-gray-400 ml-3">Other</span>
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button
                                className="btn text-white bg-teal-500 hover:bg-teal-400 w-full flex items-center">
                          <span>Submit</span>
                          <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12"
                               xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-current"
                                  d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"/>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </section>

          </main>

          {/*  Site footer */}
          <Footer/>

        </div>
    );
};
export default Contact;