import React from 'react';

function ImagesProcess() {
    return (
        <section className="relative">

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-6 pb-12 md:pt-8 md:pb-20">

            {/* Section header */}
                    <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                        <h2 className="h2 font-red-hat-display mb-4">Product images that take your brand to the next level</h2>
                        <p className="text-xl text-gray-600 dark:text-gray-400">We provide professional product images with unmatched quality. Our highly experienced photographers and designers
                         are ready to take your brand to the next level.</p>
                        <br/>

                        <p className="text-xl text-gray-600 dark:text-gray-400">Make a lasting impression with your consumers.</p>
                        <br/>
                        <br/>

                        {/*<p className="text-3xl text-gray-600 dark:text-gray-700"><a className="inline-flex items-center font-medium text-teal-500 hover:underline mt-2"*/}
                        {/*       href="https://landing-simple.siphonapp.io?utm_source=siphon-marketing&utm_medium=owned&utm_campaign=siphon-marketing-landings">*/}
                        {/*        <span>VIEW DEMO</span>*/}

                        {/*    </a></p>*/}

                    </div>



                     {/*Images grid*/}
                    <div className="grid grid-cols-12 gap-3 mt-12 md:mt-20" data-aos-id-gallery>
                        <img className="col-span-4" src={require('../images/siphon-images-1-720-540.png').default} width="360"
                             height="270" alt="About grid 01" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]"/>
                        <img className="col-span-3" src={require('../images/siphon-images-pink-540-540.png').default} width="270"
                             height="270" alt="About grid 02" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]" data-aos-delay="100"/>
                        <img className="col-span-5" src={require('../images/siphon-images-2-900-540.png').default} width="450"
                             height="270" alt="About grid 03" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]" data-aos-delay="200"/>
                        <img className="col-span-3" src={require('../images/siphon-images-purple-540-540.png').default} width="270"
                             height="270" alt="About grid 04" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]" data-aos-delay="300"/>
                        <img className="col-span-5" src={require('../images/siphon-images-3-900-540.png').default} width="450"
                             height="270" alt="About grid 05" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]" data-aos-delay="400"/>
                        <img className="col-span-4" src={require('../images/siphon-images-teal-720-540.png').default} width="360"
                             height="270" alt="About grid 06" data-aos="fade-down"
                             data-aos-anchor="[data-aos-id-gallery]" data-aos-delay="500"/>
                    </div>

                </div>
            </div>
        </section>

);
}

export default ImagesProcess;
