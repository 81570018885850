import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../utils/Dropdown';
import Transition from '../utils/Transition.js';
import SiphonLogo from '../images/Siphon Logo v2.svg'

function Header() {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      console.log(mobileNav.current.contains(target))
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // Handle light modes
  const [darkMode, setDarkMode] = useState(() => {
    // const dark = localStorage.getItem('dark-mode');
    const dark = false;
    return dark
    // if (dark === null) {
    //   return true;
    // } else {
    //   return dark === 'true';
    // }

  });

  useEffect(() => {
    localStorage.setItem('dark-mode', darkMode)
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode]);  

  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-5">
            {/* Logo */}
            <Link to="/" className="block" aria-label="Siphon">
              {/*<svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">*/}
              {/*</svg>*/}
            <img src={SiphonLogo}  width={50}

            height={50} />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">

            {/* Desktop menu links */}
            <ul className="flex flex-grow flex-wrap items-center font-medium">
              <li>
                <Link to="/" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Home</Link>
              </li>

              <li>
                <Link to="/about" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">About</Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Contact</Link>
              </li>

              {/*<li>*/}
              {/*  <Link to="/testimonials" className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 px-5 py-2 flex items-center transition duration-150 ease-in-out">Testimonials</Link>*/}
              {/*</li>*/}
              {/* 1st level: hover */}
              <Dropdown title="Products">
                {/* 2nd level: hover */}
                <li>
                  <Link to="/listings" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Listings</Link>
                </li>
                <li>
                  <Link to="/vouchers" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Vouchers</Link>
                </li>
                <li>
                  <Link to="/blurbs" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Blurbs</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/bookings" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Bookings</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/insights" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Insights</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to="/landings" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Landings</Link>
                </li>
                <li>
                  <Link to="/images" className="text-sm text-gray-600 dark:text-gray-400 hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight">Images</Link>
                </li>
              </Dropdown>

            </ul>

            {/* Desktop lights switch */}
            {/*<div className="form-switch focus-within:outline-blue flex flex-col justify-center ml-3">*/}
            {/*  <input type="checkbox" name="light-switch" id="light-switch-desktop" className="light-switch sr-only" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />*/}
            {/*  <label className="relative" htmlFor="light-switch-desktop">*/}
            {/*    <span className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10" aria-hidden="true"></span>*/}
            {/*    <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <g className="fill-current text-white" fillRule="nonzero" opacity=".88">*/}
            {/*        <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />*/}
            {/*        <circle cx="32" cy="12" r="3" />*/}
            {/*        <circle fillOpacity=".4" cx="12" cy="12" r="6" />*/}
            {/*        <circle fillOpacity=".88" cx="12" cy="12" r="3" />*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*    <span className="sr-only">Switch to light / dark version</span>*/}
            {/*  </label>*/}
            {/*</div>*/}

            {/* Desktop CTA on the right */}
            <ul className="flex justify-end flex-wrap items-center">
              <li>
                <a href={"https://siphonapp.io?utm_source=siphon-marketing&utm_medium=header-demo&utm_campaign=demo-cta"} className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-6">SHOP</a>
              </li>
            </ul>

          </nav>

          {/* Mobile menu */}
          <div className="inline-flex md:hidden">

            {/* Mobile lights switch */}
            {/*<div className="form-switch focus-within:outline-blue flex flex-col justify-center mr-6 -mt-0.5">*/}
            {/*  <input type="checkbox" name="light-switch" id="light-switch-mobile" className="light-switch sr-only" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />*/}
            {/*  <label className="relative" htmlFor="light-switch-mobile">*/}
            {/*    <span className="relative bg-gradient-to-t from-gray-100 to-white dark:from-gray-800 dark:to-gray-700 shadow-sm z-10" aria-hidden="true"></span>*/}
            {/*    <svg className="absolute inset-0" width="44" height="24" viewBox="0 0 44 24" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <g className="fill-current text-white" fillRule="nonzero" opacity=".88">*/}
            {/*        <path d="M32 8a.5.5 0 00.5-.5v-1a.5.5 0 10-1 0v1a.5.5 0 00.5.5zM35.182 9.318a.5.5 0 00.354-.147l.707-.707a.5.5 0 00-.707-.707l-.707.707a.5.5 0 00.353.854zM37.5 11.5h-1a.5.5 0 100 1h1a.5.5 0 100-1zM35.536 14.829a.5.5 0 00-.707.707l.707.707a.5.5 0 00.707-.707l-.707-.707zM32 16a.5.5 0 00-.5.5v1a.5.5 0 101 0v-1a.5.5 0 00-.5-.5zM28.464 14.829l-.707.707a.5.5 0 00.707.707l.707-.707a.5.5 0 00-.707-.707zM28 12a.5.5 0 00-.5-.5h-1a.5.5 0 100 1h1a.5.5 0 00.5-.5zM28.464 9.171a.5.5 0 00.707-.707l-.707-.707a.5.5 0 00-.707.707l.707.707z" />*/}
            {/*        <circle cx="32" cy="12" r="3" />*/}
            {/*        <circle fillOpacity=".4" cx="12" cy="12" r="6" />*/}
            {/*        <circle fillOpacity=".88" cx="12" cy="12" r="3" />*/}
            {/*      </g>*/}
            {/*    </svg>*/}
            {/*    <span className="sr-only">Switch to light / dark version</span>*/}
            {/*  </label>*/}
            {/*</div>*/}

            {/* Hamburger button */}
            <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"              
            >
              <nav id="mobile-nav" ref={mobileNav} className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar">
                <div className="py-6 pr-4 pl-20">                  
                  <Link to="/" className="block" aria-label="Siphon">
              {/*<svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">*/}
              {/*</svg>*/}
            <img src={SiphonLogo}  width={50}

            height={50} />
            </Link>
                  {/* Logo */}
                  {/*<svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*  <defs>*/}
                  {/*    <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="menulogo_a">*/}
                  {/*      <stop stopColor="#3ABAB4" offset="0%" />*/}
                  {/*      <stop stopColor="#7F9CF5" offset="100%" />*/}
                  {/*    </linearGradient>*/}
                  {/*    <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="menulogo_b">*/}
                  {/*      <stop stopColor="#3ABAB4" offset="0%" />*/}
                  {/*      <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />*/}
                  {/*    </linearGradient>*/}
                  {/*  </defs>*/}
                  {/*  <path d="M32 16h-8a8 8 0 10-16 0H0C0 7.163 7.163 0 16 0s16 7.163 16 16z" fill="url(#menulogo_a)" />*/}
                  {/*  <path d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16h8a8 8 0 1016 0h8z" fill="url(#menulogo_b)" />*/}
                  {/*</svg>*/}
                  {/* Links */}
                  <ul>
                    <li>
                      <Link to="/" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Home</Link>
                    </li>

                    <li>
                      <Link to="/about" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">About</Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link to="/blog" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Blog</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <Link to="/testimonials" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Testimonials</Link>*/}
                    {/*</li>*/}
                    <li>
                      <Link to="/contact" className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2">Contact</Link>
                    </li>
                    <li className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <span className="flex text-gray-600 dark:text-gray-400 py-2">Products</span>
                      <ul className="pl-4">
                        <li>
                          <Link to="/listings" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Listings</Link>
                        </li>
                        <li>
                          <Link to="/vouchers" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Vouchers</Link>
                        </li>
                        <li>
                          <Link to="/blurbs" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Blurbs</Link>
                        </li>

                        {/*<li>*/}
                        {/*  <Link to="/bookings" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Bookings</Link>*/}
                        {/*</li>*/}
                        <li>
                          <Link to="/landings" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Landings</Link>
                        </li>
                        <li>
                          <Link to="/images" className="text-sm flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2">Images</Link>
                        </li>
                      </ul>


                    </li>
                    <li>
                      <a href="https://bay.siphonapp.io" className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out">SHOP</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </Transition>

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
