import React from 'react';

import Header from '../partials/Header';
import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';
import FeaturesBlocks from "../partials/FeaturesBlocks";
import HeroInsights from "../partials/HeroInsights";
import InsightsProcess from "../partials/InsightsProcess";
import InsightsContent from "../partials/InsightsContent";

function Insights() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroInsights />
        <InsightsProcess />
        <InsightsContent />
        {/*<FeaturesGallery />*/}
        <FeaturesBlocks />
        {/*<Timeline />*/}
        {/*<Career />*/}
        {/*<FeaturesAnimation />*/}
        {/*<Team />*/}
        <CtaContact />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Insights;