import React, {useState} from 'react';
import Modal from '../utils/Modal';
import {useHistory} from "react-router-dom";


// Require the Node Slack SDK package (github.com/slackapi/node-slack-sdk)
const {WebClient, LogLevel} = require("@slack/web-api");

// WebClient insantiates a client that can call API methods
// When using Bolt, you can use either `app.client` or the `client` passed to listeners.
const client = new WebClient("xoxb-1388128650545-2562174752976-inC9n5soNxVtEIIB2s8M0aKe", {
    // LogLevel can be imported and used to make debugging simpler
    logLevel: LogLevel.DEBUG
});
delete client["axios"].defaults.headers["User-Agent"];


function HeroHome() {
    const history = useHistory();

    const routeChange = () => {
        let path = `/about`;
        history.push(path);
    }
    const slackChannelId = 'C02FRGLK77F'

    const [values, setValues] = useState({
        email: ''
    });
    const set = name => {
        return ({target: {value}}) => {
            setValues(oldValues => ({...oldValues, [name]: value}));
        }
    };

    const slackMessage = "Submission from Homepage Hero \n" +
      `Email: ${values.email} \n `

    const onSubmit = async (event) => {

    event.preventDefault(); // Prevent default submission
    console.log("here are values", values)

try {
  // Call the chat.postMessage method using the WebClient
  const result = await client.chat.postMessage({
    channel: slackChannelId,
    text: slackMessage
  });
  setValues({
        email: ''
      });

  routeChange()


  // console.log("here is result", result);
}
catch (error) {
  console.error(error);
}
  };


    const [videoModalOpen, setVideoModalOpen] = useState(false);

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-32 pb-10 md:pt-40 md:pb-16">

                    {/* Hero content */}
                    <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">

                        {/* Content */}
                        <div className="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left">
                            <h1 className="h1 lg:text-6xl mb-4 font-red-hat-display font-extrabold"
                                data-aos="fade-down">Customer acquisition made simple</h1>
                            <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down"
                               data-aos-delay="150">Siphon does right what other cannabis platforms do wrong.</p>
                            {/* CTA form */}
                            <form className="mt-8" data-aos="fade-down" data-aos-delay="300" onSubmit={onSubmit}>
                                <div
                                    className="flex flex-col sm:flex-row justify-center max-w-sm mx-auto sm:max-w-md md:mx-0">
                                    <input type="email" className="form-input w-full mb-2 sm:mb-0 sm:mr-2"
                                           placeholder="Email" aria-label="Email"
                                    value={values.email}  onChange={set('email')}/>
                                    <button
                                className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0 items-center">
                          <span>Chat with us</span>
                          <svg className="w-3 h-3 flex-shrink-0 mt-px ml-2" viewBox="0 0 12 12"
                               xmlns="http://www.w3.org/2000/svg">
                            <path className="fill-current"
                                  d="M6.602 11l-.875-.864L9.33 6.534H0v-1.25h9.33L5.727 1.693l.875-.875 5.091 5.091z"/>
                          </svg>
                        </button>
                                </div>
                                {/* Success message */}
                                {/* <p className="text-center md:text-left mt-2 opacity-75 text-sm">Thanks for subscribing!</p> */}
                            </form>
                            <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2"
                                data-aos="fade-down" data-aos-delay="450">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-teal-400 mr-2 flex-shrink-0"
                                         viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                    </svg>
                                    <span className="text-xl">Simple</span>
                                    {/*- finally digital marketing that keeps it simple.*/}
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-teal-400 mr-2 flex-shrink-0"
                                         viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                    </svg>
                                    <span className="text-xl">Affordable</span>
                                    {/*- customer acquisition that doesn't break the bank.*/}
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-teal-400 mr-2 flex-shrink-0"
                                         viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"/>
                                    </svg>
                                    <span className="text-xl">Measurable</span>
                                    {/*- we believe in transparancy and proving results.*/}
                                </li>
                            </ul>
                        </div>

                        {/* Mobile mockup */}
                        <div className="md:col-span-5 lg:col-span-5 text-center md:text-right" data-aos="fade-up"
                             data-aos-delay="450">
                            <div className="inline-flex relative justify-center items-center">
                                {/* Glow illustration */}
                                <svg className="absolute mr-12 mt-32 pointer-events-none -z-1 dark:opacity-40"
                                     aria-hidden="true" width="678" height="634" viewBox="0 0 678 634" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="240" cy="394" r="240" fill="url(#piphoneill_paint0_radial)"
                                            fillOpacity=".4"/>
                                    <circle cx="438" cy="240" r="240" fill="url(#piphoneill_paint1_radial)"
                                            fillOpacity=".6"/>
                                    <defs>
                                        <radialGradient id="piphoneill_paint0_radial" cx="0" cy="0" r="1"
                                                        gradientUnits="userSpaceOnUse"
                                                        gradientTransform="rotate(90 -77 317) scale(189.054)">
                                            <stop stopColor="#667EEA"/>
                                            <stop offset="1" stopColor="#667EEA" stopOpacity=".01"/>
                                        </radialGradient>
                                        <radialGradient id="piphoneill_paint1_radial" cx="0" cy="0" r="1"
                                                        gradientUnits="userSpaceOnUse"
                                                        gradientTransform="rotate(90 99 339) scale(189.054)">
                                            <stop stopColor="#9F7AEA"/>
                                            <stop offset="1" stopColor="#9F7AEA" stopOpacity=".01"/>
                                        </radialGradient>
                                    </defs>
                                </svg>
                                {/* Image inside mockup size: 290x624px (or 580x1248px for Retina devices) */}
                                <img className="absolute" src={require('../images/siphon-plat-mobile.png').default}
                                     width="290" height="624" style={{maxWidth: '84.33%'}} alt="Features illustration"/>
                                {/* iPhone mockup */}
                                <img
                                    className="relative max-w-full mx-auto md:mr-0 md:max-w-none h-auto pointer-events-none"
                                    src={require('../images/iphone-mockup.png').default} width="344" height="674"
                                    alt="iPhone mockup" aria-hidden="true"/>
                                {/* Play button */}
                                {/*<a className="absolute group" href="#0" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setVideoModalOpen(true); }} aria-controls="modal">*/}
                                {/*  <img src={require('../images/play-button.svg').default} width="96" height="96" alt="Play" />*/}
                                {/*</a>*/}
                            </div>

                            {/* Modal */}
                            <Modal id="modal" ariaLabel="modal-headline" show={videoModalOpen}
                                   handleClose={() => setVideoModalOpen(false)}>
                                <div className="relative pb-9/16">
                                    <iframe className="absolute w-full h-full"
                                            src="https://player.vimeo.com/video/174002812" title="Video"
                                            allowFullScreen></iframe>
                                </div>
                            </Modal>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
}

export default HeroHome;