import React from 'react';

function ListingsContent() {
    return (
        <section className="relative">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">


                    <div className="text-xl text-gray-600 dark:text-gray-400">
                        {/*<p className="mb-8">*/}
                        {/*    <strong className="font-medium text-gray-900 dark:text-gray-100">Our goal is to help you be*/}
                        {/*        seen</strong>. Customers visit your Listing because they're interested. They're ready to*/}
                        {/*    buy.*/}
                        {/*</p>*/}
                        {/*<p className="mb-8">*/}
                        {/*    Siphon Listings enables one-click connections between your business and our users. Customers*/}
                        {/*    can click-to-call, or simply visit your website to place their order.*/}
                        {/*</p>*/}
                        {/*<p>Each action is attributable to a Siphon user. Our Analytics </p>*/}
                        {/*<figure className="mb-8">*/}
                        {/*  <img className="w-full" src={require('../images/news-inner-image.jpg').default} width="768" height="432" alt="News inner" />*/}
                        {/*  <figcaption className="text-sm text-center text-gray-500 mt-3">Photo by Helena Lopes on Unsplash</figcaption>*/}
                        {/*</figure>*/}
                        <h3 className="h4 font-red-hat-display mb-4 text-gray-900 dark:text-gray-100">
                            Measurable marketing</h3>
                        <p className="mb-8">
                            ROI on your mind?
                        </p>
                        <p>Siphon Insights enables decision making. Get full visibility of your Listings, Vouchers, Blurbs and Products listed on Siphon. Want to test out new offerings, under your terms? You've come to the right place.</p>

                        <br/>
                        <h3 className="h4 font-red-hat-display mb-4 text-gray-900 dark:text-gray-100">
                            Do you like data?</h3>
                        <p className="mb-8">
                            You're working with the right folks.
                        </p>
                        <p>Siphon was built by data people, for data people. Our reporting is constantly improving to assist with your decision making.
                            {" "}
                            <a className="inline-flex items-center font-medium text-teal-500 hover:underline mt-2"
                               href="/contact">
                                <span>Contact us</span>
                            </a>{" "}
                             today to learn more, we'd love to hear from you!</p>

                    </div>


                </div>
            </div>

        </section>
    );
}

export default ListingsContent;
