import React from 'react';

import Header from '../partials/Header';
import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';
import FeaturesBlocks from "../partials/FeaturesBlocks";
import ImagesProcess from "../partials/ImagesProcess";
import ImagesContent from "../partials/ImagesContent";
import HeroImages from "../partials/HeroImages";
import ImagesPricingTables from "../partials/ImagesPricingTables";

function Images() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroImages />
        <ImagesProcess />
        <ImagesContent />
        <ImagesPricingTables />
        {/*<FeaturesGallery />*/}
        {/*<FeaturesBlocks />*/}
        {/*<Timeline />*/}
        {/*<Career />*/}
        {/*<FeaturesAnimation />*/}
        {/*<Team />*/}
        <CtaContact />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Images;