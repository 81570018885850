import React from 'react';

import Header from '../partials/Header';
import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';
import FeaturesBlocks from "../partials/FeaturesBlocks";
import HeroBlurbs from "../partials/HeroBlurbs";
import BlurbsProcess from "../partials/BlurbsProcess";
import BlurbsContent from "../partials/BlurbsContent";

function Listings() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroBlurbs />
        <BlurbsProcess />
        <BlurbsContent />
        {/*<FeaturesGallery />*/}
        <FeaturesBlocks />
        {/*<Timeline />*/}
        {/*<Career />*/}
        {/*<FeaturesAnimation />*/}
        {/*<Team />*/}
        <CtaContact />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Listings;