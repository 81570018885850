import React, {useEffect} from 'react';
import {
    Switch,
    Route,
    useLocation
} from 'react-router-dom';


import './css/style.scss';

import AOS from 'aos';
import {focusHandling} from 'cruip-js-toolkit';

import Home from './pages/Home';
// import Testimonials from './pages/Testimonials';
import Blurbs from './pages/Blurbs'
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import PageNotFound from './pages/PageNotFound';
import Listings from './pages/Listings';
import Vouchers from './pages/Vouchers';
import Insights from './pages/Insights';
import Images from './pages/Images';
import {initGA, PageView} from './utils/Tracking'
import Landings from "./pages/Landings";

function App() {

    const location = useLocation();




    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
            initGA('UA-177831988-2', {
  debug: true});
    PageView();
    document.body.classList.add('is-loaded')
    });

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto'
        window.scroll({top: 0})
        document.querySelector('html').style.scrollBehavior = ''
        focusHandling('outline');

    }, [location.pathname]); // triggered on route change





    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Home/>
                </Route>
                {/*<Route path="/testimonials">*/}
                {/*    <Testimonials/>*/}
                {/*</Route>*/}
                <Route path="/listings">
                    <Listings/>
                </Route>
                <Route path="/vouchers">
                    <Vouchers/>
                </Route>
                <Route path="/landings">
                    <Landings/>
                </Route>
                <Route path="/images">
                    <Images/>
                </Route>
                <Route path="/blurbs">
                    <Blurbs/>
                </Route>
                <Route path="/insights">
                    <Insights/>
                </Route>

                <Route path="/blog">
                    <Blog/>
                </Route>
                <Route path="/blog-post">
                    <BlogPost/>
                </Route>
                <Route path="/about">
                    <About/>
                </Route>
                <Route path="/contact">
                    <Contact/>
                </Route>
                <Route path="/help">
                    <Help/>
                </Route>
                <Route path="*">
                    <PageNotFound/>
                </Route>
            </Switch>
        </>
    );
}

export default App;
